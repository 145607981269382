import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function ProvidentReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Provident Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Established in 2008, Provident Housing Limited is a large-scale community developer, offering the greatest value within the residential segment. The company caters to the diverse residential needs of buyers, with a focus on aspirational home ownership.</p>

                <p className="AboutExpoPara">A 100% subsidiary of Puravankara Limited, Provident Housing carries its legacy with a portfolio of remarkable residential developments across 9 cities, namely, Bengaluru, Kochi, Mumbai, Goa, Hyderabad, Mangalore, Chennai, Coimbatore, and Pune.  With a family of 50,000+ happy residents, the company’s mammoth scale of development is evidenced by its track record of ~20 million square feet of projects: ~12.8 million square feet completed and ~7.2 million square feet of ongoing projects. Additionally, ~5.2 million square feet are planned and are under approval for new launches.</p>
            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">With proven competencies, Provident Housing thoughtfully conceives every space with a blend of unique design aesthetic, innovation, and technology. We adopt the ‘YOU’ philosophy of customer-centricity, which focuses on enhancing customer experiences and creating value for our home-buyers. We combine this credential with value, trust, and quality to offer exceptional community living experience, that aims to elevate customers’ lifestyle with homes designed for more.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default ProvidentReadMore;