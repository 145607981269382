import React, { useState } from "react"
import "./assets/styles/_index.scss"
import ProvidentAboutSidebar from "./ProvidentAboutSidebar"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"
import ProptvStand from "../../proptvstand/ProptvStand"

const videoUrlStand ="https://www.propverse.ai/utility/assets/provident-developer/ProvidentHousing.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")
const videoUrl = "https://www.propverse.ai/utility/assets/provident-developer/ProvidentHousing.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function ProvidentAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "0px",
              slidesToShow: 1,
            },
          },
    
        ],
    
      }

    return (
        <>

            <div className="ProjectAboutPageSectionProvident">
                <Container fluid className="BoothBackSection d-none d-md-none">
                    <Link to="/developers/abhee-developer/">
                        <ArrowLeft /> Back
                    </Link>

                    <div className="EnquiryBoothSection d-block d-md-none">
                        <Image src={enquiry} fluid className="" />
                    </div>
                </Container>

<div className="d-none d-md-block">
                    <ProvidentAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />
                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>

                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/provident-developer/">
                                            <p><Image src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/provident-developer/about/">
                                            <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <Image src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Provident Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Provident Developers</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">Established in 2008, Provident Housing Limited is a large-scale community developer, offering the greatest value within the residential segment.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">

                                                    <p className="AboutExpoPara">The company caters to the diverse residential needs of buyers, with a focus on aspirational home ownership.</p>

                                                    <p className="AboutExpoPara">A 100% subsidiary of Puravankara Limited, Provident Housing carries its legacy with a portfolio of remarkable residential developments across 9 cities, namely, Bengaluru, Kochi, Mumbai, Goa, Hyderabad, Mangalore, Chennai, Coimbatore, and Pune.  With a family of 50,000+ happy residents, the company’s mammoth scale of development is evidenced by its track record of ~20 million square feet of projects: ~12.8 million square feet completed and ~7.2 million square feet of ongoing projects. Additionally, ~5.2 million square feet are planned and are under approval for new launches.</p>

                                                    <p className="AboutExpoPara">With proven competencies, Provident Housing thoughtfully conceives every space with a blend of unique design aesthetic, innovation, and technology. We adopt the ‘YOU’ philosophy of customer-centricity, which focuses on enhancing customer experiences and creating value for our home-buyers. We combine this credential with value, trust, and quality to offer exceptional community living experience, that aims to elevate customers’ lifestyle with homes designed for more.</p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                             videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default ProvidentAbout